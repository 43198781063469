import React, { useEffect } from 'react';


export type ThemeContextType = {
    themeName: 'light' | 'dark';
    toggleTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContextType | null>(null);


interface ThemeProviderProps {
    children?: React.ReactNode;
}

const ThemeProvider = ({children}: ThemeProviderProps) => {
    const [themeName, setThemeName] = React.useState<'light' | 'dark'>('light');

    useEffect(() => {
        const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const themeName_ = themeMediaQuery.matches ? 'dark' : 'light';
        setThemeName(themeName_);

        themeMediaQuery.addEventListener('change', (e) => {
            setThemeName(e.matches ? 'dark' : 'light');
        });
    }, []);

    const toggleTheme = () => {
        const name = themeName === 'dark' ? 'light' : 'dark';
        localStorage.setItem('themeName', name);
        setThemeName(name);
    }

    return (
        <ThemeContext.Provider value={{themeName, toggleTheme}}>{children}</ThemeContext.Provider>
    );
};

export default ThemeProvider;