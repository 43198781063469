import StateLayer from "../System/StateLayer";
import { BaseButtonProps } from "./ButtonProps";

const BaseButton = (props: BaseButtonProps) => {
    const {
        href,
        download,
        target,
        _container_class_,
        _state_class_,
        children,
        ...other
    } = props;

    const button_ = (
        <button className={_container_class_} {...other}>
            <StateLayer
                componentName="button"
                className={_state_class_}
            >
                {children}                
            </StateLayer>
        </button>
    )

    if (href) {
        return (
            <a href={href} download={download} target={target}>
                {button_}
            </a>
        );
    }

    return button_;
}

export default BaseButton;