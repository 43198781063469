import ButtonProps from "./ButtonProps";
import FilledButton from "./FilledButton";
import ArrowDownTrayIcon from "../Icons/ArrowDownTrayIcon";

const ResumeButton = (props: ButtonProps) => {
    return (
        <FilledButton href='/JohnnySeay-Resume-2021.pdf' target='_blank'>
            <span>Resume</span>
            <ArrowDownTrayIcon variant="mini"/>
        </FilledButton>
    )
}


export default ResumeButton;