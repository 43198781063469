import React from 'react';
import ThemeProvider from './contexts/theme';

import About from './components/About/About';



import './App.css';

import { about } from './portfolio';

const StyledLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const {href, ...other} = props;
  return (
    <a className='text-primary-dark' href={href} target='_blank'>{other.children}</a>
  )
}

function App() {

  const main = (
    <div className="w-full h-screen bg-[url('./images/paper.jpg')] bg-[length:150px_150px] bg-center bg-repeat text-primary">
      <div className="w-full h-full flex flex-col justify-start items-center gap-0 backdrop-grayscale backdrop-brightness-125 backdrop-invert">
        <About {...about}/>
      </div>
      <div className='absolute bottom-0 w-full flex px-2'>
        <span className='pr-4 text-ref-neutral-80'>Inspired by <StyledLink href='https://github.com/rajshekhar26/cleanfolio' target='_blank'>Cleanfolio</StyledLink>.</span>
        <span className='pr-4 text-ref-neutral-80'>
          Names generated at <StyledLink href='https://www.calligrapher.ai/' target='_blank'>calligrapher.ai</StyledLink>.
        </span>
        <span className='pr-4 text-ref-neutral-80'>
          Built using 
          <StyledLink href='https://reactjs.org/' target='_blank'> React </StyledLink>
          and 
          <StyledLink href='https://tailwindcss.com/' target='_blank'> Tailwind CSS</StyledLink>.
        </span>
      </div>
      
    </div>
  )

  return (
    <ThemeProvider>
      {main}
    </ThemeProvider>
    
  );
}

export default App;
