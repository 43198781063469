import ButtonProps from "./ButtonProps";
import FilledButton from "./FilledButton";
import ArrowTopRightOnSquareIcon from "../Icons/ArrowTopRightOnSquareIcon";

const NPMButton = (props: ButtonProps) => {
    return (
        <FilledButton href='https://www.npmjs.com/package/@seayjohnny/card' target='_blank'>
            <span>NPM Card</span>
            <ArrowTopRightOnSquareIcon variant="mini"/>
        </FilledButton>
    )
}

export default NPMButton;