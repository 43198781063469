import React from 'react';
import IconButton from '../Buttons/IconButton';
import ResumeButton from '../Buttons/ResumeButton';
import GithubIcon from '../Icons/GithubIcon';
import LinkedInIcon from '../Icons/LinkedInIcon';

import { sample } from 'lodash';
import names from '../../names';
import ShuffleButton from '../../components/Buttons/ShuffleButton';
import NPMButton from '../Buttons/NPMButton';

export interface AboutProps {
    name?: string
    role?: string
    description?: string
    resume?: string
    social?: {
        linkedin?: string
        github?: string
    }
}

const About = (props: AboutProps) => {

    const { name, role, description, resume, social, ...other } = props;
    const [nameSVG, setName] = React.useState(sample(names));
    const shuffleName = () => {
        setName(sample(names));
    }

    return (
        <div className="px-4 py-32 mx-auto max-w-screen-xl lg:items-center lg:flex">
            <div className="max-w-4xl mx-auto text-left sm:text-center text-ref-neutral-80">
                <div className='flex flex-col items-center'>
                    <h1 className="text-3xl md:text-5xl font-extrabold mb-5">Hi, I'm</h1>
                    <div className='flex flex-col items-end'>
                        {nameSVG}
                        <div className='flex flex-col justify-end items-end text-sm'>
                            <ShuffleButton onClick={shuffleName}/>
                        </div>
                    </div>

                </div>
                <h2 className="mt-10 text-2xl md:text-4xl font-extrabold">A {role}.</h2>
                <p className="mt-4">
                    {description}
                </p>
                <div className="flex flex-wrap justify-center mt-8 gap-4">
                    <ResumeButton />
                    <IconButton href={social?.github} target='_blank'><GithubIcon variant='solid'/></IconButton>
                    <IconButton href={social?.linkedin} target='_blank'><LinkedInIcon variant='solid'/></IconButton>
                    <NPMButton />
                </div>
            </div>
        </div>
    )

}


export default About;