import IconProps from "./IconProps"

export interface BaseIconProps extends IconProps{
    outlineIcon: JSX.Element
    solidIcon: JSX.Element
    miniIcon: JSX.Element
}

const BaseIcon = ({variant, outlineIcon, solidIcon, miniIcon}: BaseIconProps) => {
    switch(variant) {
        case 'solid':
            return solidIcon;
        case 'mini':
            return miniIcon;
        case 'outline':
        default:
            return outlineIcon
    }
}

export default BaseIcon;