import ButtonProps from "./ButtonProps";
import BaseButton from "./BaseButton";

const IconButton = (props: ButtonProps) => {
    const {
        disabled,
        className,
    } = props;

    
    const _shape_rules_ = `h-10 w-10 rounded-button`
    const _label_rules_ =  disabled ? 'text-on-surface/30 label-large' : `text-primary label-large`

    const _layout_rules_ = `flex items-center justify-center text-center`
    const _state_rules_ = disabled ? '' : `hover:bg-primary/[.08] active:bg-primary/[.12]`

    const _container_class_ = `${_shape_rules_} ${className}`
    const _state_class_ = `${_shape_rules_} ${_label_rules_} ${_layout_rules_} ${_state_rules_}`

    return <BaseButton 
        _container_class_={_container_class_}
        _state_class_={_state_class_}
        {...props}
    />
}

export default IconButton;