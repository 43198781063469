import {ReactComponent as Name1} from './Name1.svg';
import {ReactComponent as Name2} from './Name2.svg';
import {ReactComponent as Name3} from './Name3.svg';
import {ReactComponent as Name4} from './Name4.svg';
import {ReactComponent as Name5} from './Name5.svg';
import {ReactComponent as Name6} from './Name6.svg';
import {ReactComponent as Name7} from './Name7.svg';
import {ReactComponent as Name8} from './Name8.svg';
import {ReactComponent as Name9} from './Name9.svg';
import {ReactComponent as Name10} from './Name10.svg';
import {ReactComponent as Name11} from './Name11.svg';
import {ReactComponent as Name12} from './Name12.svg';
import {ReactComponent as Name13} from './Name13.svg';
import {ReactComponent as Name14} from './Name14.svg';
import {ReactComponent as Name15} from './Name15.svg';
import {ReactComponent as Name16} from './Name16.svg';

const minx = '250'
const miny = '350'
const width = '1500'
const height = '500'
const vb = [minx, miny, width, height].join(' ')
// const vb = '';

const names = [
    <Name1  className="name-path" width={700} height={150}/>,
    <Name2  className="name-path" width={700} height={150}/>,
    <Name3  className="name-path" width={700} height={150}/>,
    <Name4  className="name-path" width={700} height={150}/>,
    <Name5  className="name-path" width={700} height={150}/>,
    <Name6  className="name-path" width={700} height={150}/>,
    <Name7  className="name-path" width={700} height={150}/>,
    <Name8  className="name-path" width={700} height={150}/>,
    <Name9  className="name-path" width={700} height={150}/>,
    <Name10 className="name-path" width={700} height={150}/>,
    <Name11 className="name-path" width={700} height={150}/>,
    <Name12 className="name-path" width={700} height={150}/>,
    <Name13 className="name-path" width={700} height={150}/>,
    <Name14 className="name-path" width={700} height={150}/>,
    <Name15 className="name-path" width={700} height={150}/>,
    <Name16 className="name-path" width={700} height={150}/>,
]

export default names;