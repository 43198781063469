import BaseButton from "./BaseButton";
import ButtonProps from "./ButtonProps";

const FilledButton = (props: ButtonProps) => {
    const {
        icon,
        disabled,
        className,
    } = props;

    
    const _shape_rules_ = `h-10 rounded-button`
    const _container_rules_ = `enabled:bg-primary disabled:bg-on-surface/10` 
    const _label_rules_ =  disabled ? 'text-on-surface/30 label-large' : `text-on-primary label-large`

    const _icon_rules = icon ? 'pl-4 pr-6' : 'px-6'
    const _layout_rules_ = `flex items-center gap-2 text-center ${_icon_rules}`
    const _state_rules_ = disabled ? '' : `hover:bg-on-primary/[.08] active:bg-on-primary/[.12]`

    const _container_class_ = `${_shape_rules_} ${_container_rules_} ${className}`
    const _state_class_ = `${_shape_rules_} ${_label_rules_} ${_layout_rules_} ${_state_rules_}`

    return <BaseButton 
        _container_class_={_container_class_}
        _state_class_={_state_class_}
        {...props}
    />
}

export default FilledButton;