import ShuffleIcon from "../Icons/ShuffleIcon";
import IconButton from "./IconButton";
import ButtonProps from "./ButtonProps";

const ShuffleButton = (props: ButtonProps) => {
    return (
        <IconButton {...props}>
            <ShuffleIcon variant="mini"/>
        </IconButton>
    )
}

export default ShuffleButton;