import { AboutProps } from "./components/About/About";

export const about: AboutProps = {
    name: 'Johnny Seay',
    role: 'Full Stack Engineer',
    description: 'I design and implement scalable web services, applications, and APIs.',
    resume: '',
    social: {
        linkedin: 'https://www.linkedin.com/in/johnny-seay-966097185/',
        github: 'https://github.com/seayjohnny'
    }
}