import React, { ReactNode } from "react";

export interface StateLayerProps extends React.HTMLAttributes<HTMLDivElement>{
    componentName?: string | undefined
    stateLayerColor?: string | undefined
}

export type State = 'enabled' | 'disabled' | 'hovered' | 'focused' | 'pressed' | undefined;

const StateLayer = (props: StateLayerProps) => {
    const {
        stateLayerColor,
        componentName,
        children,
        className,
    } = props

    const [stateColor, setStateColor] = React.useState(stateLayerColor);

    const id = React.useId();

    const _class_ = `${className} `

    const _componentName = componentName ? componentName : ''

    return (
        <div className={_class_} id={`${_componentName}_stateLayer_${id}`}>
            {children}
        </div>
    )
}

export default StateLayer;